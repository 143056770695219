import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Terms of Use`}</h1>
    <p>{`This site and the mobile applications and services available in connection with this site and mobile application (the “App”) are made available to you by Pixel Foundry, LLC (“Pixel Foundry”, “We”) subject to these Terms of Service, including those set forth in the Privacy Policy (the “Terms”). By accessing, using or downloading any materials from the App, you agree to follow and be bound by the Terms, which may be updated by us from time to time without notice to you. You can review the most current version of the Terms at any time here. We and third party providers may make improvements and/or changes in the products, services, mobile applications, features, programs, and prices described in this App at any time without notice. Certain provisions of the Terms may be supplemented or superseded by expressly designated legal notices or terms located on particular pages at this App. The App is not intended for and is not designed to attract children under 13 years of age. If you do not agree with the Terms, please do not use this App. BY CONTINUING TO USE THE APP, YOU ARE INDICATING YOUR AGREEMENT TO THE TERMS AND ALL REVISIONS THEREOF.`}</p>
    <p>{`We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the App or any portion thereof with or without notice. You agree that we shall not be liable to you or to any third party for any modification, suspension or discontinuance of the App or any portion thereof.`}</p>
    <h2>{`Subscription payments, renewals, and cancellation`}</h2>
    <p>{`If you choose to buy a Harbor Premium subscription, payment will be charged to your Apple ID account at the confirmation of purchase. The subscription automatically renews unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can manage and cancel your subscriptions by going to your account settings on the App Store after purchase.`}</p>
    <p>{`All purchases are final. Pixel Foundry cannot issue refunds for purchases charged to your Apple ID.`}</p>
    <h2>{`Electronic Communications`}</h2>
    <p>{`The App may provide you with the ability to send emails or other communications to third party service providers, advertisers, other users, and/or us. You agree to use communication methods available on the App only to send communications and materials related to the subject matter for which we provided the communication method, and you further agree that all such communications by you shall be deemed your Content and shall be subject to and governed by the Terms. By using any of the communications methods available on the App, you acknowledge and agree that (a) all communications methods constitute public, and not private, means of communication between you and the other party or parties, (b) communications sent to or received from third party service providers, advertisers or other third parties are not be endorsed, sponsored or approved by us (unless expressly stated otherwise by Pixel Foundry, LLC) and (c) communications are not pre-reviewed, post-reviewed, screened, archived or otherwise monitored by us in any manner, though we reserve the right to do so at any time at its sole discretion in accordance with the Terms. You agree that all notices, disclosures and other communications that we provide to you electronically shall satisfy any legal requirement that such communications be in writing.`}</p>
    <h2>{`Disclaimer of Warranties and Liability`}</h2>
    <p>{`THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND CONTENT AVAILABLE IN THE APP ARE PROVIDED TO YOU “AS IS” AND WITHOUT WARRANTY. PIXEL FOUNDRY, LLC AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, AND LICENSORS HEREBY DISCLAIM ALL WARRANTIES WITH REGARD TO SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND CONTENT, INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT. PIXEL FOUNDRY, LLC AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, AND LICENSORS MAKE NO WARRANTY THAT (a) THE SERVICE WILL MEET YOUR REQUIREMENTS; (b) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (c) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE; (d) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS; AND (e) ANY ERRORS IN THE APP WILL BE CORRECTED.`}</p>
    <p>{`YOU EXPRESSLY AGREE TO RELEASE PIXEL FOUNDRY, LLC, ITS SUBSIDIARIES, AFFILIATES, OFFICERS, AGENTS, REPRESENTATIVES, EMPLOYEES, PARTNERS AND LICENSORS (THE “RELEASED PARTIES”) FROM ANY AND ALL LIABILITY CONNECTED WITH YOUR ACTIVITIES, AND PROMISE NOT TO SUE THE RELEASED PARTIES FOR ANY CLAIMS, ACTIONS, INJURIES, DAMAGES, OR LOSSES ASSOCIATED WITH YOUR ACTIVITIES. YOU ALSO AGREE THAT IN NO EVENT SHALL THE RELEASED PARTIES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH (a) YOUR USE OR MISUSE OF THE APP, (b) YOUR DEALINGS WITH THIRD PARTY SERVICE PROVIDERS OR ADVERTISERS AVAILABLE THROUGH THE APP, (c) ANY DELAY OR INABILITY TO USE THE APP EXPERIENCED BY YOU, (d) ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES, OR CONTENT OBTAINED THROUGH THE APP, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, EVEN IF PIXEL FOUNDRY, LLC HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.`}</p>
    <h2>{`Indemnity`}</h2>
    <p>{`You agree to indemnify and hold us and our subsidiaries, affiliates, officers, agents, representatives, employees, partners and licensors harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of Content you submit, post, transmit or otherwise seek to make available through the App, your use of the App, your connection to the App, your violation of the Terms, or your violation of any rights of another person or entity.`}</p>
    <h2>{`Termination`}</h2>
    <p>{`You agree that we may, under certain circumstances and without prior notice, immediately terminate your account and/or access to the App. Cause for such termination shall include, but not be limited to, (a) breaches or violations of the Terms or other incorporated agreements or guidelines, (b) requests by law enforcement or other government agencies, (c) a request by you (self-initiated account deletions), (d) discontinuance or material modification to the App (or any portion thereof), (e) unexpected technical or security issues or problems, (f) extended periods of inactivity, and/or (g) nonpayment of any fees owed by you in connection with the App. Termination of your account may include (x) removal of access to all offerings within the App, (y) deletion of your information, files and Content associated with or inside your account, and (z) barring of further use of the App. Further, you agree that all terminations for cause shall be made in our sole discretion and that we shall not be liable to you or any third party for any termination of your account or access to the App.`}</p>
    <h2>{`Applicable Laws`}</h2>
    <p>{`This App is controlled by Pixel Foundry, LLC from its offices within the United States of America. We make no representation that the Content in the App or the App are appropriate or available for use in other locations, and access to them from territories where their content or use is illegal is prohibited. Those who choose to access this App from locations outside of the United States do so on their own initiative and are responsible for compliance with applicable local laws. You may not use or export the Content in violation of U.S. export laws and regulations. Any action related to the App, the Content or the Terms shall be governed by New York law and controlling U.S. federal law, without regard to conflicts of laws thereof. You hereby consent and submit to the exclusive jurisdiction and venue in the state and federal courts located in Westchester County, New York for any legal proceedings related to the App or the Terms. If any party hereto brings any suit or action against another for relief, declaratory or otherwise, arising out of these Terms, the prevailing party will have and recover against the other party, in addition to all court costs and disbursements, such sum as the court may adjudge to be reasonable attorneys’ fees. Except to the extent prohibited by applicable law, the parties agree that any claim or cause of action arising out of or related to use of the App or the Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred. Sections 9, 11, 12, 13, 15 and 16 shall survive termination of your account and/or the Terms.`}</p>
    <h2>{`General`}</h2>
    <p>{`You agree that no joint venture, partnership, employment, or agency relationship exists between you and Pixel Foundry, LLC as a result of the Terms or your use of the App. The Terms constitute the entire agreement between you and Pixel Foundry, LLC with respect to your use of the App. The failure of us to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect. You may not assign, delegate or otherwise transfer your account or your obligations under these Terms without the prior written consent of us. We have the right, in its sole discretion, to transfer or assign all or any part of its rights under these Terms and will have the right to delegate or use third party contractors to fulfill its duties and obligations under these Terms and in connection with the App. Our notice to you via email, regular mail, or notices or links on the App shall constitute acceptable notice to you under the Terms. A printed version of the Terms and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to the Terms to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. Section titles and headings in the Terms are for convenience only and have no legal or contractual effect. Any rights not expressly granted herein are reserved.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      